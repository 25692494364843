import React from 'react';

import { Link } from 'react-router-dom'


import Empregister from './frontend/empRegisre';


export default function Home() {
 
  return (
    <main className='homepage'>
      <h1>Home Page</h1>
      <Link to="/frontend/empRegisre">Register</Link><br/>
      <Link to="/frontend/empLogin">Login</Link>      
      
    </main>
  )
} 