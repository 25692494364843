import React, { useState } from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom'


export default function Empregister() {
  const [username, setUsername] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")

  const [error, setErr] = React.useState(null)
  
  // const [jobOwner, setJOwner] = React.useState({
  //   name: "",
  //   email: "",
  //   password: "",
  //   jobId: ""
  // })

  // function handleChange(event) {
  //   setJOwner(prev => (
  //     {
  //       ...prev,
  //       [event.target.name]:[event.target.value]
  //     }
  //   ))
  // }

//   function HandleSubmit(event) {
//     event.preventDefault();
//     // axios.post("http://localhost:8081/regEmp", jobOwner)
//     axios.post("http://localhost:8081/serv/auth/register", jobOwner)

//     .then((res)=> {
//         // navigate("/home")
//     })
//     .catch(err=> {
//       setErr(err.response.data) 
//     })

//     // 
    
// }
  const HandleSubmit = async (event)=> {
    event.preventDefault();
    // axios.post("http://localhost:8081/regEmp", jobOwner)
    // axios.post("http://localhost:8081/api/auth/login", jobOwner)
    try {
      const res = await axios.post("https://hiresava.opportunitieshub.in/api/auth/register", {username, email, password}, {withCredentials: true})
      console.log(res.data);
    } catch (err) {
      setErr(err.response.data);
      console.log(err);
    }
  }
  return (
    <main className='homepage'>
      <h2>Employer register section</h2>
      <form onSubmit={HandleSubmit}>
        

        <label htmlFor=''>name:</label><br/>
        <input type='text' placeholder='enter name' name='username' onChange={event=> setUsername(event.target.value)} /><br/>

        <label htmlFor=''>email:</label><br/>
        <input type='email' placeholder='enter email' name='email' onChange={event=> setEmail(event.target.value)} /><br/>

        <label htmlFor=''>password:</label><br/>
        <input placeholder='enter password' name='password' onChange={event=> setPassword(event.target.value)} /><br/>

        <h3>{error && error}</h3>

        <button>submit</button>
      </form>

      <Link to="/frontend/empLogin">Login</Link>      
    </main>
  )
}