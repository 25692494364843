import React from 'react';
import axios from 'axios';

import { Link, useNavigate } from 'react-router-dom'


export default function Emplogin() {
  const [username, setUsername] = React.useState("")
  const [password, setPassword] = React.useState("")

  const [error, setErr] = React.useState(null)

  const [currentUser, setCurrentuser] = React.useState("")
  const navigate = useNavigate();

    
  // }
  const HandleSubmit = async (event)=> {
    event.preventDefault();
    // axios.post("http://localhost:8081/regEmp", jobOwner)
    // axios.post("http://localhost:8081/api/auth/login", jobOwner)
    try {
      const res = await axios.post("https://hiresava.opportunitieshub.in/api/auth/login", {username, password}, {withCredentials: true})
      console.log(res.data);

      localStorage.setItem("currentUser", JSON.stringify(res.data));
      navigate("/frontend/jobPosts")

    } catch (err) {
      setErr(err.response.data);
      console.log(err);
    }

    
    
}
console.log(password)
  return (
    <main className='homepage'>
      <h2>Employer login section</h2>
      <form onSubmit={HandleSubmit}>
        <label htmlFor=''>name:</label><br/>
        <input type='text' placeholder='enter name' name='username' onChange={event=> setUsername(event.target.value)} /><br/>

        <label htmlFor=''>password:</label><br/>
        <input placeholder='enter password' name='password' onChange={event=> setPassword(event.target.value)} /><br/>

        <h3>{error && error}</h3>

        <button>Login</button>
      </form>

      <Link to="/frontend/empRegisre">Register</Link>
    </main>
  )
}