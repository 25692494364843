import React from 'react';
import axios from 'axios';

import { Link, useNavigate } from 'react-router-dom'

export default function Jobpostings() {
  const [logout, setLgout] = React.useState("");
  const navigate = useNavigate();

  const currentUser = JSON.parse(localStorage.getItem("currentUser"))
  const Signoff = async ()=> {
    try {
      await axios.post("https://hiresava.opportunitieshub.in/api/auth/logout");
      localStorage.setItem("currentUser", null);
      navigate("/")
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <main>
      <h4>{currentUser.username} </h4>
      <h1>current jobs</h1>

      <Link>
        <button onClick={Signoff}>Logout</button>
      </Link>
    </main>
  )
}