import React from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';


import Home from './home';
// import Bookmesa from './Houses/Book-house/houseFiles/bookMESAhouse';
import Empregister from './frontend/empRegisre';
import Emplogin from './frontend/empLogin';
import Jobpostings from './frontend/jobPosts';


export default function App() {
  return (
    <main>
      
      <BrowserRouter>
    
        <Routes>
          < Route path="/" element={<Home/>}/>
          < Route path="/frontend/empRegisre" element={<Empregister/>}/>
          < Route path="/frontend/empLogin" element={<Emplogin/>}/>
          < Route path="/frontend/jobPosts" element={<Jobpostings/>}/>


        </Routes>
      </BrowserRouter>
      
    </main>
  )
} 